<template>
  <div class="file">
      <img :src="fileType(modal.ApxUrl)" alt="" srcset="">
      <span @click="download">【{{modal.ApxFileAnthorName || modal.ApxFileName}}】</span>
      <a-popconfirm
            title="确定要删除吗?"
            ok-text="是的"
            cancel-text="不"
            @confirm="confirm"
        >
      <img class="delete" v-if="canDelete" src="@/assets/big-del.png">
      </a-popconfirm>
  </div>
</template>

<script>
import { fileType } from "@/utils/util"
const whiteTypeList = ['doc','xls', 'unknown']
export default {
    name:'file',
    props: {
        modal:{
            type:Object,
            default: ()=>{
                return {
                    AppendixId: 0,
                    ApxFileName: '',
                    ApxTag: '',
                    ApxUrl:'xxx.pdf',
                    ApxFileAnthorName:''
                }
            }
        },
        canDelete:{
            type:Boolean,
            default: true
        },
        index: {
            type:Number,
            default: 0
        }
    },
    methods: {
        confirm() {
            this.$emit('delete', {index:this.index,...this.modal})
        },
        fileType:fileType,
        download() {
            this.$emit('preview', {index:this.index,...this.modal})
        }
    }
}
</script>

<style lang="less">
.file {
    margin-bottom: 15Px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 40Px;
    img {
        width: 22Px;
        height: 22Px;
        position: relative;
        top: -4Px;
    }
    span {
        font-size: 14Px;
        font-weight: bold;
        color: #6E76FF;
    }
    .delete {
        width:14Px;
        height: 14Px;
    }
}
</style>