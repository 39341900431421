<template>
  <div class="lesson-design-header" :style="{ background: bgColor }">
    <div class="title">
      <slot name="icon"></slot>
    </div>
    <div class="lesson-content">
      <div class="slot-content" :style="{ padding: soltPadding }">
        <slot name="content"></slot>
      </div>
    </div>

    <!-- 恢复历史删除模块 -->
    <a-popover v-if="hiddenHistory" title="" placement="topRight">
      <template slot="content">
        <div class="miss-actions-content">
          <div
            class="miss-action-item"
            v-for="(item, index) in missActions"
            :key="item.ActionId"
          >
            <a-badge status="processing" :text="item.ActionName" />
            <a-button
              type="link"
              icon="reload"
              @click="resetAction(item, index)"
              >恢复</a-button
            >
          </div>
          <div v-if="missActions.length == 0">
            <a-empty description="没有历史删除模块" />
          </div>
        </div>
      </template>
      <a-button
        v-if="showRightBtn"
        class="miss-action"
        icon="history"
        type="link"
        >历史删除模块</a-button
      >
    </a-popover>

    <a-popover title="" placement="topRight" trigger="click" :visible="visible">
      <template slot="content">
        <div class="tool-warp">
          <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="上传文件">
              <a-alert
                message="每上传完一个文件请保存后再上传另一个文件"
                type="warning"
                description="仅支持小于500M的文件上传"
                show-icon
              />
              <br />
              <a-upload
                name="file"
                action="/api/MyLesson/UploadFiles"
                :file-list="fileList"
                @change="uploadHandleChange"
                :remove="removeFile"
                :multiple="true"
              >
                <a-button> <a-icon type="upload" /> 点击上传文件 </a-button>
              </a-upload>
              <br />
              <!-- <a-upload
                directory
                action="/api/MyLesson/UploadFiles"
                @change="uploadHandleChange"
              >
                <a-button> <a-icon type="upload" /> 点击上传文件夹 </a-button>
              </a-upload>
              <br /> -->
              <div>
                <a-input
                  addon-before="资源标签"
                  placeholder="请输入资源标签用于文件分组"
                  v-model="apxTag"
                />
              </div>
              <br />
              <!-- <div>
                <a-input
                  addon-before="文件重命名"
                  placeholder="请输入文件名称"
                  v-model="filename"
                />
              </div>
              <br /> -->
              <div style="text-align: right">
                <a-button type="default" @click="cancelUpload">取消</a-button>
                <span style="padding: 8px"></span>
                <a-button type="primary" @click="saveFile">保存</a-button>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="文件链接" force-render>
              <br />
              <div>
                <a-input
                  addon-before="文件链接"
                  placeholder="请粘贴文件链接"
                  v-model="link"
                />
              </div>
              <br />
              <div>
                <a-input
                  addon-before="资源标签"
                  placeholder="请输入资源标签用于文件分组"
                  v-model="apxTag"
                />
              </div>
              <br />
              <div>
                <a-input
                  addon-before="链接名称"
                  placeholder="请输入链接名称"
                  v-model="linkname"
                />
              </div>
              <br />
              <div style="text-align: right">
                <a-button type="default" @click="cancelUpload">取消</a-button>
                <span style="padding: 8px"></span>
                <a-button type="primary" @click="saveLink">保存</a-button>
              </div>
            </a-tab-pane>
            <a-tab-pane key="3" tab="历史删除">
              <div class="miss-content">
                <miss-file
                  v-for="(item, index) in missingList"
                  :key="item.AppendixId"
                  :modal="item"
                  :index="index"
                  @recovery="recoveryAction"
                  @delete="permanentlyDelete"
                ></miss-file>
                <div v-if="missingList.length == 0">
                  <a-empty description="没有历史删除文件" />
                </div>
              </div>
              <br />
              <div style="text-align: right">
                <a-button type="default" @click="cancelUpload">取消</a-button>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </template>
      <img
        :src="editIcon"
        @click="edit"
        v-if="canEdit"
        class="edit-btn"
        alt=""
        srcset=""
      />
    </a-popover>
  </div>
</template>

<script>
import MissFile from "./MissFile.vue";
import { delAliYunFile } from "@/api/user";
export default {
  components: { MissFile },
  name: "lesson-design-header",
  props: {
    bgColor: {
      type: String,
      default: "#ffffff",
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    isUpload: {
      type: Boolean,
      default: false,
    },
    editIcon: {
      type: String,
      default: "",
    },
    soltPadding: {
      type: String,
      default: "30px",
    },
    //必须 1课前|2课中|3课后
    apxType: {
      type: Number,
      default: 0,
    },
    missingList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    missActions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    showRightBtn: {
      type: Boolean,
      default: false,
    },
    //是否隐藏历史删除
    hiddenHistory: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      fileList: [],
      filename: "",
      linkname: "",
      link: "",
      apxTag: "",
      title: "",
    };
  },
  mounted() {},
  methods: {
    edit() {
      if (this.isUpload) {
        this.visible = true;
      } else {
        this.$emit("edit");
      }
    },
    resetAction(model, index) {
      let params = { ...model, index: index };
      this.$emit("resetMissAction", params);
    },
    /**
     * 上传文件
     */
    uploadHandleChange(info) {
      // if (info.file.status !== "uploading") {
      //   console.log(info.file, info.fileList);
      //   this.fileList = info.fileList
      // }
      if (info.file.status === "done") {
        this.$message.success(`${info.file.name} 文件上传成功`);
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} 文件上传失败`);
      }

      let fileList = [...info.fileList];
      // if (fileList.length > 1) {
      //   this.removeFile(fileList[0]);
      // }
      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      // fileList = fileList.slice(-1);
      // 2. read from response and show file link
      fileList = fileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      });
      this.fileList = fileList;
    },
    removeFile(obj) {
      delAliYunFile({ key: obj.response[0].SourceUrl });
    },
    /**
     * 保存文件
     */
    saveFile() {
      if (this.fileList.length == 0) {
        this.$message.error("请上传文件");
        return;
      }
      // let file = this.fileList[0].response[0];
      // let params = {
      //   purpose: this.filename,
      //   ApxTag: this.apxTag,
      //   ...file,
      // };
      // this.$emit("uploadFile", params);

      this.fileList.forEach((item) => {
        let file = item.response[0];
        let params = {
          purpose: this.filename,
          ApxTag: this.apxTag,
          ...file,
        };
        this.$emit("uploadFile", params);
      });

      this.fileList = [];
      this.filename = "";
      this.apxTag = "";
      this.visible = false;
    },
    /**
     * 保存链接
     */
    saveLink() {
      if (this.link.length == 0) {
        this.$message.error("请输入资源链接");
        return;
      }
      if (this.linkname.length == 0) {
        this.$message.error("请输入资源链接的教学用途（链接名称）");
        return;
      }
      let params = {
        linkUrl: this.link,
        ApxTag: this.apxTag,
        purpose: this.linkname,
      };
      this.$emit("uploadLink", params);
      this.link = "";
      this.linkname = "";
      this.apxTag = "";
      this.visible = false;
    },
    cancelUpload() {
      this.visible = false;
    },
    recoveryAction(model) {
      this.$emit("recovery", model);
    },
    permanentlyDelete(model) {
      this.$emit("permanently", model);
    },
  },
};
</script>

<style lang="less">
.miss-content {
  min-height: 160px;
}
.miss-actions-content {
  width: 300px;
  min-height: 200px;
  .miss-action-item {
    display: flex;
    align-items: center;
  }
}
.lesson-design-header {
  position: relative;
  min-height: 102px;
  margin-top: 30px;
  margin-bottom: 60px;
  .miss-action {
    position: absolute;
    right: 0;
    top: 10px;
  }
  .title {
    position: absolute;
    left: 20px;
    top: -30px;
    z-index: 100;
  }
  .lesson-content {
    min-height: 102px;
    border: 1px dotted #8e8e93;
    opacity: 1;
    border-radius: 8px;
    display: flex;
    align-items: center;
    width: 100%;
    .slot-content {
      width: 100%;
      padding: 30px;
    }
  }
  .edit-btn {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    bottom: 15px;
    cursor: pointer;
  }
}
</style>>