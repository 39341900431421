<template>
  <div class="miss-file">
    <a-button type="link" style="color: #52c41a" icon="reload"
    @click="resetFile"
      ><span style="color: #52c41a">恢复</span></a-button
    >
    <img :src="fileType(modal.ApxUrl)" alt="" srcset="" />
    <span @click="download"
      >【{{ modal.ApxFileAnthorName || modal.ApxFileName }}】</span
    >
    <a-popconfirm
      title="确定要永久删除吗?"
      ok-text="是的"
      cancel-text="不"
      @confirm="confirm"
    >
      <img class="delete" src="@/assets/big-del.png" />
    </a-popconfirm>
  </div>
</template>

<script>
import { fileType } from "@/utils/util";
export default {
  name: "miss-file",
  props: {
    modal: {
      type: Object,
      default: () => {
        return {
          AppendixId: 0,
          ApxFileName: "",
          ApxTag: "",
          ApxUrl: "xxx.pdf",
          ApxFileAnthorName: "",
        };
      },
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    confirm() {
        let params = {...this.modal}
        params.index = this.index
      this.$emit("delete", params);
    },
    fileType: fileType,
    download() {
      this.$emit('preview', {index:this.index})
    },
    resetFile(){
        let params = {type:1,...this.modal}
        params.index = this.index
        this.$emit('recovery', params)
    }
  },
};
</script>

<style lang="less">
.miss-file {
    margin-bottom: 15Px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 40Px;
    img {
        width: 22Px;
        height: 22Px;
        position: relative;
        top: -2Px;
    }
    span {
        font-size: 14Px;
        font-weight: bold;
        color: #6E76FF;
    }
    .delete {
        width:14Px;
        height: 14Px;
    }
}
</style>